import { WButton } from "../../components/button/WButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { cn } from "../../utils/classNames.ts";
import { scheduledDeleteMutation } from "./pricesQuery.ts";
import P from "./PricesView.module.css";

export function DeleteScheduledPricesModal({ item, isSelected }: { item: string; isSelected?: boolean }) {
    const deleteScheduledMutation = useStandardMutation(scheduledDeleteMutation);

    async function onDeleteScheduled(item: string) {
        await deleteScheduledMutation.mutateAsync({ id: item });
    }

    return (
        <WModal
            button={
                <WButton
                    className={cn(isSelected && P.White)}
                    size="xs"
                    color="primary"
                    variant="ghost"
                    icon="jp-trash-icon"
                    action={undefined}
                />
            }
        >
            <WModalContent
                title="Delete Scheduled Prices"
                label="Delete"
                modalAction={async () => await onDeleteScheduled(item)}
            >
                <p>
                    Deleting these scheduled prices means that these prices will be removed and will not be published to
                    live.
                </p>
            </WModalContent>
        </WModal>
    );
}
