import type { ResourceTypePrices } from "@warrenio/api-spec/spec.oats.gen";
import { mustFindBy } from "../../mock-api/mustFindBy.ts";

export type PricingResourceType = ResourceTypePrices["resource_type"];

export interface PricingResourceItemType {
    type: PricingResourceType;
    title: string;
    unit: string;
    gradual: boolean;
}

export const pricingResourceTypes: PricingResourceItemType[] = [
    {
        type: "CPU",
        title: "CPU",
        unit: "num cpus",
        gradual: true,
    },
    {
        type: "RAM",
        title: "RAM",
        unit: "gigabytes",
        gradual: true,
    },
    {
        type: "BLOCK_STORAGE",
        title: "Block Storage",
        unit: "gigabytes",
        gradual: true,
    },
    {
        type: "BLOCK_STORAGE_BACKUP",
        title: "Block Storage Backup",
        unit: "gigabytes",
        gradual: true,
    },
    {
        type: "BLOCK_STORAGE_SNAPSHOT",
        title: "Block Storage Snapshot",
        unit: "gigabytes",
        gradual: true,
    },
    {
        type: "WINDOWS_LICENSE",
        title: "Windows License",
        unit: "",
        gradual: false,
    },
    {
        type: "OBJECT_STORAGE",
        title: "Object Storage",
        unit: "",
        gradual: false,
    },
    {
        type: "UNASSIGNED_FLOATING_IP",
        title: "Unassigned Floating IP",
        unit: "",
        gradual: false,
    },
    {
        type: "ASSIGNED_FLOATING_IP",
        title: "Assigned Floating IP",
        unit: "",
        gradual: false,
    },
    {
        type: "LOAD_BALANCER",
        title: "Load Balancer",
        unit: "",
        gradual: false,
    },
];

export function getResourceTypeInfo(type: PricingResourceType): PricingResourceItemType {
    return mustFindBy(pricingResourceTypes, (item) => item.type, type, "resource type");
}
