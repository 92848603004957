import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import {
    type PricingResourceItemType,
    type PricingResourceType,
    pricingResourceTypes,
} from "./pricingResourceTypes.ts";

export interface ResourceTypeSelectProps
    extends WSelectStyleProps,
        WSelectValueProps<PricingResourceItemType, PricingResourceType> {
    disabledKeys?: PricingResourceType[];
}

export function ResourceTypeSelect({ ...props }: ResourceTypeSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select location"
            items={pricingResourceTypes}
            getKey={(item) => item.type}
            getTextValue={(item) => item.title}
            {...props}
        >
            {(item) => <LeftRight title={item.title} />}
        </WSelect>
    );
}
