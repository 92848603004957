import FF from "../../components/forms/FormField.module.css";
import P from "./PricesView.module.css";

import type { PricingList } from "@warrenio/api-spec/spec.oats.gen";
import { useMemo, useState } from "react";
import type { SelectedOs } from "../../modules/compute/os/os.ts";
import { usePackages } from "../../modules/compute/vmPackages.ts";
import { VmSizeSelectCustom } from "../../modules/compute/VmSizeSelect.tsx";
import {
    convertToSizeValue,
    getDefaultPackageForOs,
    getSizeComponentRanges,
    useSizeParams,
    type SizeValue,
} from "../../modules/compute/vmSizeSelectUtils.ts";
import { EMPTY_PRICES, listToObjectPrices } from "../../modules/pricing/resourcePricing.ts";
import { cn } from "../../utils/classNames.ts";

const dummyOsVersion = "1";
const fallbackSize: SizeValue = { disks: 40, vcpu: 1, ram: 2048, isCustom: true };

export function PackagePrices({
    priceList,
    location,
    currency,
}: {
    priceList: PricingList | undefined;
    location: string;
    currency: string;
}) {
    //#region Hooks
    const sizeParams = useSizeParams();
    const allSizePackages = usePackages();

    /** This is `undefined` when the user has not selected a size yet */
    const [selectedSize, setSelectedSize] = useState<SizeValue | undefined>();
    const [osName, setOsName] = useState("ubuntu");

    const os = useMemo((): SelectedOs => ({ os_name: osName, os_version: dummyOsVersion }), [osName]);
    const ranges = useMemo(() => getSizeComponentRanges(sizeParams, os), [sizeParams, os]);
    const defaultSize = useMemo(() => {
        const defaultPackageForOs = getDefaultPackageForOs(sizeParams, allSizePackages, os);
        return defaultPackageForOs ? convertToSizeValue(defaultPackageForOs, true) : fallbackSize;
    }, [allSizePackages, sizeParams, os]);
    //#endregion

    // PATTERN: To prevent duplication of code in calculating the initial state and then updating it in event handlers, always use "derived state" and just make event handlers unset any "derivable" state.
    const size = selectedSize ?? defaultSize;

    function osChange(value: string) {
        setOsName(value);

        // Force "resetting" to default size when changing OS
        setSelectedSize(undefined);
    }

    return (
        <div className="p-2 lightBlock mb-2">
            <div className="pb-2">Package prices</div>

            <div className="flex items-start gap-0.5rem">
                <div className={P.Os}>
                    <div className={cn(P.Radio, osName === "ubuntu" && P.isActive)} onClick={() => osChange("ubuntu")}>
                        Other
                    </div>
                    <div
                        className={cn(P.Radio, osName === "windows" && P.isActive)}
                        onClick={() => osChange("windows")}
                    >
                        Windows
                    </div>
                </div>

                <div className={cn(P.Radios, FF.FormFieldRadioGroup)}>
                    <VmSizeSelectCustom
                        vmData={{ ...os, location }}
                        priceList={priceList ? listToObjectPrices(priceList, currency) : EMPTY_PRICES}
                        value={size}
                        ranges={ranges}
                        onChange={setSelectedSize}
                    />
                </div>
            </div>
        </div>
    );
}
