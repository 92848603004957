import FF from "../../components/forms/FormField.module.css";
import T from "../../components/forms/TextField.module.css";
import P from "./PricesView.module.css";

import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type { PriceFieldsBase, PricingList } from "@warrenio/api-spec/spec.oats.gen";
import { useAtomValue } from "jotai/react";
import { type ReactNode, useEffect, useState } from "react";
import { FieldError, Input, NumberField } from "react-aria-components";
import type { Settings } from "react-slick";
import { Badge } from "../../components/Badge.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import ContentPane from "../../components/ContentPane.tsx";
import { CurrencyHourly, CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { MonthYearDate } from "../../components/l10n/DateFormat.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip, WTooltip } from "../../components/WTooltip.tsx";
import { siteCurrencyAtom } from "../../config.ts";
import { todoAction } from "../../dev/todoSubmit.ts";
import { CountryFlag } from "../../modules/location/CountryFlag.tsx";
import type { LocationWithEnabled } from "../../modules/location/location.ts";
import { usePricingLocations } from "../../modules/location/query.ts";
import { allHostPoolsQueryAtom, type HostPoolWithType } from "../../modules/pools/query.ts";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { AddNewItemModal } from "./AddNewItemModal.tsx";
import { AddPricesModal } from "./AddPricesModal.tsx";
import { DeleteScheduledPricesModal } from "./DeleteScheduledPricesModal.tsx";
import { EditGradualPricesModal } from "./EditGradualPricesModal.tsx";
import { PackagePrices } from "./PackagePrices.tsx";
import { historyPricesAtom } from "./pricesQuery.ts";
import { getResourceTypeInfo, type PricingResourceType } from "./pricingResourceTypes.ts";
import { ScheduleNewPricesModal } from "./ScheduleNewPricesModal.tsx";

export interface PriceFieldsBaseWithType extends PriceFieldsBase {
    resource_type: PricingResourceType;
}

export function PricesView() {
    const history = useSuspenseQueryAtom(historyPricesAtom);
    const locations = usePricingLocations();
    const pools = [...useSuspenseQueryAtom(allHostPoolsQueryAtom).values()];
    const siteCurrency = useAtomValue(siteCurrencyAtom);

    const [selectedKey, setSelectedKey] = useState<string>("");
    const [selectedPrices, setSelectedPrices] = useState<PricingList | null>(null);
    /* XXX: For schedule new prices */
    const [currentPrices, setCurrentPrices] = useState<PricingList | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<string>("");
    const [selectedStamp, setSelectedStamp] = useState<number>(0);
    const [selectedTitle, setSelectedTitle] = useState<ReactNode>(null);
    const [initialSlide, setInitialSlide] = useState<number>(0);
    const [isEditing, setIsEditing] = useState(false);

    const date = new Date();
    const currentStamp = new Date(date.getFullYear(), date.getMonth(), 1).valueOf();
    const dates = Object.keys(history);

    type SelectedAppliesType = Record<string, string[]>;

    const selectedAppliesTo: SelectedAppliesType = {
        DEFAULT: [],
        ...locations.reduce((acc: SelectedAppliesType, curr: LocationWithEnabled) => {
            acc[curr.slug] = [];
            return acc;
        }, {}),
        ...pools.reduce((acc: SelectedAppliesType, curr: HostPoolWithType) => {
            acc["host_pool:" + curr.uuid] = []; /* XXX: not a good hack? */
            return acc;
        }, {}),
    };

    useEffect(() => {
        if (Object.keys(history).length) {
            const prevArray = dates.filter((item: string) => apiDatetimeToDate(item).valueOf() < currentStamp);
            const current = prevArray.length - 1;
            toggleActive(dates[current], dates[prevArray.length]);
            setInitialSlide(current);
            setCurrentPrices(history[dates[current]]);
        }
    }, [history]);

    if (selectedPrices) {
        for (const o of locations) {
            const applyGroup = Object.keys(selectedPrices[siteCurrency]).some(
                (locKeyword: string) => locKeyword === o.slug,
            )
                ? o.slug
                : "DEFAULT";
            selectedAppliesTo[applyGroup].push(o.display_name);
        }

        for (const o of pools) {
            const foundLocation: LocationWithEnabled | undefined = locations.find(
                (l: LocationWithEnabled) => l.slug === o.location,
            );
            const applyGroup = Object.keys(selectedPrices[siteCurrency]).some(
                (poolKeyword: string) => poolKeyword === `host_pool:${o.uuid}`,
            )
                ? `host_pool:${o.uuid}`
                : Object.keys(selectedPrices[siteCurrency]).some(
                        (locKeyword: string) => locKeyword === foundLocation?.slug,
                    )
                  ? foundLocation?.slug
                  : "DEFAULT";
            if (applyGroup) selectedAppliesTo[applyGroup].push(`${foundLocation?.display_name} - ${o.name}`);
        }
    }

    function toggleActive(key: string, next: string | undefined) {
        setSelectedKey(key);
        setSelectedStamp(apiDatetimeToDate(key).valueOf());
        slideTitle(key, next);
        setSelectedLocation(Object.keys(history[key][siteCurrency])[0]);
        setSelectedPrices(history[key]);
    }

    function toggleIsEditing() {
        setIsEditing(!isEditing);
    }

    function schedulePrices(value: string) {
        console.log(value);
        /* TODO: add current prices with key to history */
    }

    function slideTitle(prev: string, next: string | undefined) {
        const prevStamp = apiDatetimeToDate(prev).valueOf();
        let isCurrent = false;

        let title = "Previous Prices";
        const prevTitle = <MonthYearDate date={prev} />;
        let finishTitle = <>...</>;

        if (next) {
            const nextStamp = apiDatetimeToDate(next).valueOf();
            finishTitle = <MonthYearDate date={apiDatetimeToDate(next)} />;

            if (prevStamp <= currentStamp && nextStamp > currentStamp) {
                title = "Current Prices";
                isCurrent = true;
            } else if (nextStamp > currentStamp) {
                title = "Scheduled Prices";
            }
        } else {
            title = "Scheduled Prices";
        }

        const selectedTitle = (
            <>
                {title}
                {!isCurrent && (
                    <>
                        : {prevTitle} - {finishTitle}
                    </>
                )}
            </>
        );

        setSelectedTitle(selectedTitle);
    }

    const slides = dates.map((item: string, index: number) => {
        const prev = apiDatetimeToDate(item);
        const prevStamp = prev.valueOf();
        const next = dates[index + 1];
        let isCurrent = false;
        let isFuture = false;

        let title = "Previous Prices";
        const prevTitle = <MonthYearDate date={prev} />;
        let finishTitle = <>...</>;

        if (next) {
            const nextStamp = apiDatetimeToDate(next).valueOf();
            finishTitle = <MonthYearDate date={apiDatetimeToDate(next)} />;

            if (prevStamp <= currentStamp && nextStamp > currentStamp) {
                title = "Current Prices";
                isCurrent = true;
            } else if (nextStamp > currentStamp) {
                title = "Scheduled Prices";
                isFuture = true;
            }
        } else {
            title = "Scheduled Prices";
            isFuture = true;
        }

        const isSelected = selectedKey === item;

        return (
            <div
                key={index}
                className={cn(P.Slide, isCurrent && P.Current, isFuture && P.Future, isSelected && P.Active)}
                onClick={() => toggleActive(item, next)}
            >
                <div className={P.Content}>
                    <div className={cn(isSelected ? P.White : "color-muted", "font-size-small pb-4")}>{title}</div>
                    <div className="font-size-subtitle">
                        {prevTitle} - {finishTitle}
                    </div>

                    {isFuture && (
                        <div className={P.Delete}>
                            <DeleteScheduledPricesModal item={item} isSelected={isSelected} />
                        </div>
                    )}
                    {isCurrent && <div className={P.Banner}>Current</div>}
                </div>
            </div>
        );
    });

    const settings: Settings = {
        dots: false,
        infinite: false,
        initialSlide,
    };

    const locationElements = selectedPrices
        ? Object.keys(selectedPrices[siteCurrency]).map((key: string) => {
              let location = locations.find((s) => s.slug === key);
              const pool = pools.find((o) => `host_pool:${o.uuid}` === key);
              let title = key;
              let icon;

              if (location) {
                  title = location?.display_name;
                  icon = <CountryFlag code={location?.country_code} />;
              }

              if (pool) {
                  location = locations.find((s) => s.slug === pool.location);
                  title = `${location?.display_name} - ${pool.name}`;
                  icon = <CountryFlag code={location?.country_code} />;
              }

              return (
                  <div
                      key={key}
                      className={cn(P.Radio, selectedLocation === key && P.isActive)}
                      onClick={() => setSelectedLocation(key)}
                  >
                      {icon}
                      {title}
                  </div>
              );
          })
        : [];

    const priceRows: PriceFieldsBaseWithType[] =
        selectedPrices && selectedLocation
            ? selectedPrices[siteCurrency][selectedLocation].flatMap((value) =>
                  value.resource_prices.map((child: PriceFieldsBase, i: number) => ({
                      resource_type: value.resource_type,
                      num: i,
                      ...child,
                  })),
              )
            : [];

    const disabledKeys = selectedPrices ? Object.keys(selectedPrices[siteCurrency]) : [];

    console.log(currentPrices);

    return (
        <>
            <AdminTitle title="Prices" />
            <p className="p-2 color-muted">
                All configured platform prices are listed here.
                <br />
                You can view and schedule price updates for different locations.
            </p>

            <div className={P.Slides}>
                <WCarousel settings={settings} slideWidth={200}>
                    {slides}
                    <div className={P.Slide}>
                        <ScheduleNewPricesModal
                            onChange={(value: string) => schedulePrices(value)}
                            disabledKeys={Object.keys(history)}
                        />
                    </div>
                </WCarousel>
            </div>

            <div className="pt-3">
                <Separator />
            </div>

            <ContentPane>
                <div className="font-size-subtitle">{selectedTitle}</div>
                {selectedStamp > currentStamp ? (
                    <p className="color-muted pt-1">
                        These prices will take effect on the 01 <MonthYearDate date={selectedKey} />, 00:00 UTC
                    </p>
                ) : null}

                <div className="flex items-center pt-4 pb-2">
                    {locationElements}
                    <AddPricesModal disabledKeys={disabledKeys} />

                    <WButton
                        className={P.DeleteLocation}
                        color="primary"
                        variant="ghost"
                        size="md"
                        icon="jp-trash-icon"
                        action={todoAction}
                    >
                        Delete
                    </WButton>
                </div>
                <div className="color-muted pb-4">
                    Applied to:
                    <Badge noDot color="text">
                        {selectedLocation && selectedAppliesTo[selectedLocation].length
                            ? selectedAppliesTo[selectedLocation].join(", ")
                            : "[NOT USED]"}
                    </Badge>
                </div>

                <PackagePrices
                    priceList={selectedPrices ? selectedPrices : undefined}
                    currency={siteCurrency}
                    location={selectedLocation}
                />

                <WTable afterTable={<AddNewItemModal />}>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th className="text-right">Price Per Hour</th>
                            <th className="text-right">
                                Estimated Price per Month
                                <InfoTooltip
                                    className="ml-1"
                                    text="Monthly prices are estimates, 1 month is approximated to 730 hours."
                                />
                            </th>
                            <th className="text-right">
                                <WButton
                                    color="primary"
                                    variant="ghost"
                                    size="xs"
                                    icon="jp-icon-edit"
                                    action={() => toggleIsEditing()}
                                >
                                    Edit
                                </WButton>
                            </th>
                        </tr>
                    </thead>
                    <WTableBody>
                        {priceRows.map((item, index) => (
                            <tr key={index}>
                                <td className="color-primary">{item.description}</td>
                                <td className="text-right">
                                    <div className="w-10rem inline-block">
                                        {isEditing ? (
                                            <NumberField
                                                className={T.NumberField}
                                                onChange={todoAction}
                                                value={item.price_per_unit ?? NaN}
                                                aria-label="Set price per hour"
                                                isRequired
                                                formatOptions={{
                                                    maximumFractionDigits: 10,
                                                }}
                                            >
                                                <div>
                                                    <Input className={T.Input} />
                                                </div>
                                                <FieldError />
                                            </NumberField>
                                        ) : (
                                            <CurrencyHourly value={item.price_per_unit} />
                                        )}
                                    </div>
                                </td>
                                <td className="text-right">
                                    <CurrencyMonthly value={item.price_per_unit} accurate={true} />
                                </td>
                                <td className="text-right">
                                    <div className="flex gap-0.5em justify-end">
                                        {getResourceTypeInfo(item.resource_type).gradual && (
                                            <EditGradualPricesModal value={item} />
                                        )}

                                        <WTooltip text="Delete">
                                            <WButton
                                                ariaLabel="Delete"
                                                color="primary"
                                                variant="ghost"
                                                size="xs"
                                                icon="jp-trash-icon"
                                                action={todoAction}
                                            />
                                        </WTooltip>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </WTableBody>
                </WTable>
            </ContentPane>

            <Separator />

            <div className={FF.FormActions}>
                <WButton color="primary" variant="basic" size="md" action={todoAction}>
                    Publish
                </WButton>

                <WButton color="default" variant="basic" size="md" action={todoAction}>
                    Cancel
                </WButton>
            </div>
        </>
    );
}
