import { useState } from "react";
import { FieldError, Input, NumberField } from "react-aria-components";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { default as T } from "../../components/forms/TextField.module.css";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { getResourceTypeInfo, type PricingResourceType } from "./pricingResourceTypes.ts";
import { ResourceTypeSelect } from "./ResourceTypeSelect.tsx";

export function AddNewItemModal() {
    const [selectedKey, setSelectedKey] = useState<PricingResourceType | undefined>();
    const monthlyPrice = 0.0123456;

    const selectedInfo = selectedKey ? getResourceTypeInfo(selectedKey) : undefined;

    return (
        <WModal
            button={
                <WModalButton
                    className="w-full"
                    color="primary"
                    size="lg"
                    variant="dashed"
                    label="Add New Item"
                    icon="jp-icon-add"
                />
            }
        >
            <WModalContent title="Add New Item" label="Add" modalAction={todoAction}>
                <div className="pb-2">
                    <WTextField wide label="Resource type" onChange={undefined}>
                        <ResourceTypeSelect
                            valueKey={selectedKey}
                            disabledKeys={["RAM"]}
                            onChange={(item, key) => {
                                console.log("Key: %s, Item: %o", key, item);
                                setSelectedKey(key);
                            }}
                        />
                    </WTextField>
                </div>

                <WTable
                    afterTable={
                        <WButton
                            className="w-full"
                            color="primary"
                            size="md"
                            variant="dashed"
                            icon="jp-icon-add"
                            action={todoAction}
                        >
                            Add Graduality
                        </WButton>
                    }
                >
                    <thead>
                        <tr>
                            {selectedInfo?.gradual && <th>From Units ({selectedInfo.unit})</th>}
                            <th className="text-right">Price per Hour</th>
                            <th className="text-right">
                                Estimated Price per Month
                                <InfoTooltip
                                    className="ml-1"
                                    text="Monthly prices are estimates, 1 month is approximated to 730 hours."
                                />
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <WTableBody>
                        <tr>
                            {selectedInfo?.gradual && (
                                <td>
                                    <NumberField
                                        className={T.NumberField}
                                        onChange={todoAction}
                                        defaultValue={NaN}
                                        aria-label="Set from units"
                                        isRequired
                                        formatOptions={{
                                            maximumFractionDigits: 0,
                                        }}
                                    >
                                        <div>
                                            <Input className={T.Input} />
                                        </div>
                                        <FieldError />
                                    </NumberField>
                                </td>
                            )}

                            <td className="text-right">
                                <NumberField
                                    className={T.NumberField}
                                    onChange={todoAction}
                                    defaultValue={NaN}
                                    aria-label="Set price per hour"
                                    isRequired
                                    formatOptions={{
                                        maximumFractionDigits: 10,
                                    }}
                                >
                                    <div>
                                        <Input className={T.Input} />
                                    </div>
                                    <FieldError />
                                </NumberField>
                            </td>
                            <td className="text-right">
                                <CurrencyMonthly value={monthlyPrice} accurate={true} />
                            </td>
                            <td className="text-right">
                                <WButton
                                    ariaLabel="Delete"
                                    color="primary"
                                    variant="ghost"
                                    size="xs"
                                    icon="jp-trash-icon"
                                    action={todoAction}
                                />
                            </td>
                        </tr>
                    </WTableBody>
                </WTable>
            </WModalContent>
        </WModal>
    );
}
