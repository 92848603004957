import { WButton } from "../../components/button/WButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { todoAction, todoEvent } from "../../dev/todoSubmit.ts";
import { LocationHostSelectCustom } from "../../modules/location/LocationHostSelect.tsx";
import { usePricingLocations } from "../../modules/location/query.ts";
import { allHostPoolsQueryAtom } from "../../modules/pools/query.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import P from "./PricesView.module.css";

export function AddPricesModal({ disabledKeys }: { disabledKeys: string[] }) {
    const locations = usePricingLocations();
    const pools = [...useSuspenseQueryAtom(allHostPoolsQueryAtom).values()];

    return (
        <WModal
            button={
                <WTooltip text="Add Prices">
                    <WButton
                        className={P.AddLocation}
                        color="primary"
                        variant="dashed"
                        size="md"
                        icon="jp-icon-add"
                        action={todoAction}
                    />
                </WTooltip>
            }
        >
            <WModalContent title="Add Prices" label="Add Prices" modalAction={todoAction}>
                <div className="max-w-24rem">
                    <p className="color-muted pb-2">Choose location or server class to add new prices</p>

                    <LocationHostSelectCustom
                        disabledKeys={disabledKeys}
                        baseItems={[...locations, ...pools]}
                        onChange={(item, key) => {
                            console.log("Key: %s, Item: %o", key, item);
                            todoEvent();
                        }}
                    />

                    <p className="color-muted font-size-small pt-2">
                        Each location can have their own price list that applies to all resources from that location.
                        DEFAULT prices are used if location specific prices are not defined. Server class specific price
                        list can be added to have different compute prices in one location.
                    </p>
                </div>
            </WModalContent>
        </WModal>
    );
}
