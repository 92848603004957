import { useState } from "react";
import LR from "../../components/LeftRight.module.css";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WSelect } from "../../components/forms/WSelect.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { MonthYearDate } from "../../components/l10n/DateFormat.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import P from "./PricesView.module.css";

export function ScheduleNewPricesModal({
    onChange,
    disabledKeys,
}: {
    onChange: (value: string) => void;
    disabledKeys: string[];
}) {
    const [scheduledDate, setScheduledDate] = useState<string>("");

    const date = new Date();
    const startDate = new Date(date.setMonth(date.getMonth() + 1)).valueOf();
    const endDate = new Date(date.setFullYear(date.getFullYear() + 2)).valueOf();
    const items: string[] = [];

    if (startDate < endDate) {
        let current = new Date(startDate);

        while (current.valueOf() < endDate) {
            items.push(`${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, "0")}`);
            current = new Date(current.setMonth(current.getMonth() + 1));
        }
    }

    return (
        <WModal
            button={
                <WButton
                    className={P.New}
                    color="primary"
                    variant="dashed"
                    size="lg"
                    icon="jp-icon-add"
                    action={undefined}
                >
                    Schedule New Prices
                </WButton>
            }
        >
            <WModalContent title="Schedule New Prices" label="Add" modalAction={() => onChange(scheduledDate)}>
                <div className="max-w-24rem">
                    <div className="color-muted pb-1">Starting from</div>

                    <WSelect
                        getKey={(item) => item ?? "__none__"}
                        items={items}
                        itemClassName={LR.item}
                        valueClassName={LR.value}
                        aria-label="Select date"
                        disabledKeys={disabledKeys}
                        getTextValue={(item) => item ?? "None"}
                        onChange={(item, key) => {
                            setScheduledDate(key);
                        }}
                    >
                        {(item) => {
                            return (
                                <LeftRight
                                    icon={<MaskIcon className="jp-calendar-icon size-0.75rem" />}
                                    title={<MonthYearDate date={item} />}
                                />
                            );
                        }}
                    </WSelect>

                    <p className="color-muted pt-2 font-size-small">
                        These prices will take effect on the first day of the selected month at 00:00 UTC.
                    </p>
                </div>
            </WModalContent>
        </WModal>
    );
}
