import LR from "../../components/LeftRight.module.css";

import { groupBy } from "remeda";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import type { HostPoolWithType } from "../pools/query.ts";
import { CountryFlag, CountryFlagPadding } from "./CountryFlag.tsx";
import type { LocationWithEnabled } from "./location.ts";

export type PricingLocationItem = LocationWithEnabled | HostPoolWithType;

export interface LocationHostSelectProps extends WSelectStyleProps, WSelectValueProps<PricingLocationItem, string> {
    baseItems: PricingLocationItem[];
    disabledKeys?: string[];
}

export function isLocation(item: PricingLocationItem): item is LocationWithEnabled {
    return "slug" in item;
}

export function getKey(item: PricingLocationItem): string {
    return isLocation(item) ? item.slug : `host_pool:${item.uuid}`;
}

export function LocationHostSelectCustom({ baseItems, ...props }: LocationHostSelectProps) {
    const groupedItems = Object.values(groupBy(baseItems, (l) => (isLocation(l) ? l.slug : l.location))).flat();

    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select location"
            items={groupedItems}
            getKey={getKey}
            getTextValue={(item) => (isLocation(item) ? item.display_name : item.name)}
            {...props}
        >
            {(item) => (
                <LeftRight
                    icon={isLocation(item) ? <CountryFlag code={item.country_code} /> : <CountryFlagPadding />}
                    title={isLocation(item) ? item.display_name : item.name}
                />
            )}
        </WSelect>
    );
}
