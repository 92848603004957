import { FieldError, Input, NumberField } from "react-aria-components";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import FF from "../../components/forms/FormField.module.css";
import { default as T, default as TF } from "../../components/forms/TextField.module.css";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { cn } from "../../utils/classNames.ts";
import type { PriceFieldsBaseWithType } from "./PricesView.tsx";
import { getResourceTypeInfo } from "./pricingResourceTypes.ts";

export function EditGradualPricesModal({ value }: { value: PriceFieldsBaseWithType }) {
    const title = getResourceTypeInfo(value.resource_type).title ?? "";

    return (
        <WModal button={<WModalButton label="Edit Gradual Prices" inTable icon="jp-graph-bar-icon" />}>
            <WModalContent title={`Edit ${title} Gradual Prices`} label="Edit" modalAction={todoAction}>
                <WTextField wide label="Resource type" value={title} onChange={undefined}>
                    <Input readOnly className={cn(FF.FormFieldInput, TF.Input)} />
                </WTextField>

                <WTable
                    afterTable={
                        <WButton
                            className="w-full"
                            color="primary"
                            size="md"
                            variant="dashed"
                            icon="jp-icon-add"
                            action={todoAction}
                        >
                            Add Graduality
                        </WButton>
                    }
                >
                    <thead>
                        <tr>
                            <th>
                                From Units (<TODO />)
                            </th>
                            <th className="text-right">Price per Hour</th>
                            <th className="text-right">
                                Estimated Price per Month
                                <InfoTooltip
                                    className="ml-1"
                                    text="Monthly prices are estimates, 1 month is approximated to 730 hours."
                                />
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <WTableBody>
                        <tr>
                            <td>
                                <NumberField
                                    className={T.NumberField}
                                    onChange={todoAction}
                                    defaultValue={NaN}
                                    aria-label="Set from units"
                                    isRequired
                                >
                                    <div>
                                        <Input className={T.Input} />
                                    </div>
                                    <FieldError />
                                </NumberField>
                            </td>
                            <td className="text-right">
                                <NumberField
                                    className={T.NumberField}
                                    onChange={todoAction}
                                    defaultValue={NaN}
                                    aria-label="Set price per hour"
                                    isRequired
                                >
                                    <div>
                                        <Input className={T.Input} />
                                    </div>
                                    <FieldError />
                                </NumberField>
                            </td>
                            <td className="text-right"></td>
                            <td className="text-right">
                                <WButton
                                    ariaLabel="Delete"
                                    color="primary"
                                    variant="ghost"
                                    size="xs"
                                    icon="jp-trash-icon"
                                    action={todoAction}
                                />
                            </td>
                        </tr>
                    </WTableBody>
                </WTable>
            </WModalContent>
        </WModal>
    );
}
